













import { defineComponent, onMounted, ref } from "@vue/composition-api";

import ChannelTalkIcon from "@/assets/ElementsImage/ChannelTalkIcon.vue";

export default defineComponent({
  components: {
    ChannelTalkIcon,
  },
  setup() {
    const l = () => {
      if (window.ChannelIOInitialized) {
        return;
      }
      window.ChannelIOInitialized = true;
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
      document.head.appendChild(s);
    };

    const isButtonVisible = ref(true);

    onMounted(() => {
      l();
      if (window.ChannelIO) {
        window.ChannelIO("onShowMessenger", () => {
          isButtonVisible.value = false;
        });
        window.ChannelIO("onHideMessenger", () => {
          isButtonVisible.value = true;
        });
        return;
      }

      const ch: {
        (...args: any): void;
        q: any[];
        c(args: any): void;
      } = (...args: any) => {
        ch.c(args);
      };
      ch.q = [];
      ch.c = (args: any) => {
        ch.q.push(args);
      };

      window.ChannelIO = ch;
      window.ChannelIO("boot", {
        pluginKey: process.env.VUE_APP_CHANNELTALK_PLUGIN_KEY,
        customLauncherSelector: ".channel-talk__btn",
        profile: {
          url: window.location.href,
        },
      });
      window.ChannelIO("onShowMessenger", () => {
        isButtonVisible.value = false;
      });
      window.ChannelIO("onHideMessenger", () => {
        isButtonVisible.value = true;
      });
    });

    const showMessengerHandler = () => {
      window.ChannelIO("showMessenger");
    };

    return {
      isButtonVisible,
      showMessengerHandler,
    };
  },
});

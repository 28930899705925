var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"base-header",class:{ 'base-header--extend': _vm.extend },style:({ 'background-color': _vm.shopInfo.color })},[_c('div',{staticClass:"base-header__store-main-container",class:{ 'base-header__store-main-container--extend': _vm.extend }},[_c('router-link',{staticClass:"base-header__store-link",class:{ 'base-header__store-link--extend': _vm.extend },attrs:{"to":{ name: 'Myshop' }}},[_c('div',{staticClass:"base-header__store-profile-wrap"},[(_vm.shopInfo.profileImgUrl && !_vm.isProfileBroken)?_c('img',{staticClass:"base-header__store-profile-img",attrs:{"src":_vm.shopInfo.profileImgUrl,"alt":"","width":"43","height":"43"},on:{"error":function($event){_vm.isProfileBroken = true}}}):_c('ProfileIcon')],1),(!_vm.extend)?_c('div',{staticClass:"base-header__store-name",class:{
          'base-header__store-name--dark': _vm.checkDarkTheme(_vm.shopInfo.color),
        }},[_vm._v(" "+_vm._s(_vm.shopInfo.name)+" ")]):_vm._e()]),_c('nav',{staticClass:"base-header__nav"},[_c('router-link',{staticClass:"base-header__nav-link",attrs:{"to":{ name: 'Delivery' }}},[_c('TruckIcon',{attrs:{"width":"32","height":"32","color":_vm.iconColor}}),_c('span',{staticClass:"base-header__nav-link-text"},[_vm._v("주문/배송 조회")])],1),_c('router-link',{staticClass:"base-header__nav-link",attrs:{"to":{ name: 'Cart' }}},[_c('BasketIcon',{attrs:{"width":"21","height":"26","color":_vm.iconColor}}),_c('span',{staticClass:"base-header__nav-link-text"},[_vm._v("장바구니")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.cartCount > 0),expression:"cartCount > 0"}],staticClass:"base-header__cart-count"},[_vm._v(_vm._s(_vm.formatCartCount))])],1)],1)],1),(_vm.extend)?_c('router-link',{staticClass:"base-header__shop-name-link",class:{
      'base-header__shop-name-link--dark': _vm.checkDarkTheme(_vm.shopInfo.color),
    },attrs:{"to":{ name: 'Myshop' }}},[_vm._v(_vm._s(_vm.shopInfo.name))]):_vm._e(),(_vm.extend)?_c('div',{staticClass:"base-header__intro-text",class:{
      'base-header__intro-text--dark': _vm.checkDarkTheme(_vm.shopInfo.color),
      'base-header__intro-text--empty': !_vm.shopInfo.introText,
    },domProps:{"textContent":_vm._s(_vm.shopInfo.introText)}}):_vm._e(),(_vm.extend && _vm.hasSns)?_c('div',{staticClass:"base-header__sns-fixed-area"},[_c('div',{staticClass:"base-header__sns-container"},[(_vm.shopInfo.snsList.insta)?_c('a',{staticClass:"base-header__sns-link",attrs:{"href":_vm.shopInfo.snsList.insta,"target":"_blank"}},[_c('div',{staticClass:"base-header__sns-icon-wrap"},[_c('InstagramLogo',{attrs:{"width":"15","height":"15","gradientId":"ginsta"}})],1),_vm._v(" 인스타그램 ")]):_vm._e(),(_vm.shopInfo.snsList.naverBlog)?_c('a',{staticClass:"base-header__sns-link",attrs:{"href":_vm.shopInfo.snsList.naverBlog,"target":"_blank"}},[_c('div',{staticClass:"base-header__sns-icon-wrap"},[_c('BlogLogo',{attrs:{"color":"#00cc52","width":"22","height":"22"}})],1),_vm._v(" 블로그 ")]):_vm._e(),(_vm.shopInfo.snsList.youtube)?_c('a',{staticClass:"base-header__sns-link",attrs:{"href":_vm.shopInfo.snsList.youtube,"target":"_blank"}},[_c('div',{staticClass:"base-header__sns-icon-wrap"},[_c('YoutubeLogo',{attrs:{"color":"#f00"}})],1),_vm._v(" 유튜브 ")]):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }














































































































import { defineComponent, computed, ref } from "@vue/composition-api";

import checkDarkTheme from "@/components/Shop/Hooks/checkDarkTheme";

import { Shop } from "@/components/Shop/Types";

import LeftArrowIcon from "@/assets/ElementsImage/LeftArrowIcon.vue";
import BasketIcon from "@/assets/ElementsImage/BasketIcon.vue";
import TruckIcon from "@/assets/ElementsImage/TruckIcon.vue";
import ProfileIcon from "@/assets/ElementsImage/ProfileIcon2.vue";
import InstagramLogo from "@/assets/Logo/InstagramGradientBorderLogo.vue";
import BlogLogo from "@/assets/Logo/BlogLogoSquare.vue";
import YoutubeLogo from "@/assets/Logo/YoutubeLogo.vue";

export default defineComponent({
  components: {
    LeftArrowIcon,
    BasketIcon,
    TruckIcon,
    ProfileIcon,
    InstagramLogo,
    BlogLogo,
    YoutubeLogo,
  },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
    extend: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = context.root.$store;

    const iconColor = computed(() => {
      const lightThemeColor = "#212529";
      const darkThemeColor = "#fff";

      if (!props.shopInfo) {
        return lightThemeColor;
      }

      return checkDarkTheme(props.shopInfo.color)
        ? darkThemeColor
        : lightThemeColor;
    });

    const cartCount = computed(() => {
      if (!store.state.cartItems || !props.shopInfo) return 0;
      const cartItems = store.state.cartItems[props.shopInfo.idx];

      return cartItems ? cartItems.length : 0;
    });

    const formatCartCount = computed(() => {
      if (cartCount.value > 999) return "999+";

      return cartCount.value.toString();
    });

    const isProfileBroken = ref(false);

    const hasSns = computed(() => {
      if (!props.shopInfo) return false;

      return Object.values(props.shopInfo.snsList).some((sns) => !!sns);
    });

    return {
      cartCount,
      formatCartCount,
      iconColor,
      isProfileBroken,
      hasSns,
      checkDarkTheme,
    };
  },
});

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    width: {
      type: [String, Number],
      default: 21,
    },
    height: {
      type: [String, Number],
      default: 25,
    },
    color: {
      type: String,
      default: "#868E96",
    },
  },
});



















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    width: {
      type: [String, Number],
      default: 26,
    },
    height: {
      type: [String, Number],
      default: 27,
    },
  },
});
